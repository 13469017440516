import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
  
import { HashLink } from "react-router-hash-link";



class Footer extends React.Component {

    

  render() {
    return (
      <React.Fragment>
        <section className="footer-bg" id='footer'>
          <div className="container-fluid">
            <Row>
              <Col className='text-center' lg='3'  sm='12'>
              <Link className="navbar-brand logo-absolute logo" to={`${process.env.PUBLIC_URL}/`}>
            <img src='./images/logo1.svg' alt="footerLogo" height="150" className='footer-logo'  />
              {/* <img src="./images/study_revolution.png" alt="" height="40" /> */}
              </Link>
              </Col>
     <Col lg="9">
     <Row>
        

        
        <Col lg="5" xs='6'>
             
             <div className="">
               <p className=" text-uppercase  footer-title mb-0">
               Farmkrisp Stores
               </p>
               <ul className="list-unstyled footer-sub-menu">
                 <li className="f-16">
                   <Link  to={`${process.env.PUBLIC_URL}/franchise`} className="">
                     Franchise
                   </Link>
                 </li>
                <li>

                 <HashLink
                    // to={`${process.env.PUBLIC_URL}/faq`}
                    to="franchise/#fofo-section"
                    scroll={(el) =>
                      el.scrollIntoView({ behavior: "smooth", block: "start" })
                    }
            
                  >
                    FOFO Model
                  </HashLink><br></br>
                </li>

                 {/* <li className="f-16">
                   <Link  to={`${process.env.PUBLIC_URL}/franchise/#fofo-section`} className="">
                     FOFO Model
                   </Link>

                   
                 </li> */}
                    <li>

                    <HashLink
                    // to={`${process.env.PUBLIC_URL}/faq`}
                    to="franchise/#foco-section"
                    scroll={(el) =>
                      el.scrollIntoView({ behavior: "smooth", block: "start" })
                    }
            
                  >
                    FOCO Model
                  </HashLink>
                    </li>

                 {/* <li className="f-16">
                   <Link  to={{
                     pathname:`${process.env.PUBLIC_URL}/franchise`,
                     hash:'#foco-section'
                   }}  className="">
                     FOCO Model
                   </Link>
                 </li> */}
                 <li className="f-16">
                   <Link  to={`${process.env.PUBLIC_URL}/franchise-form`} className="">
                     Own FarmKrisp Franchise
                   </Link>
                 </li>
               </ul>
             </div>

             
           </Col>

           <Col lg="4" xs='6'>
             <div className="mb-5">
               <p className="text-uppercase  footer-title mb-0">
                 About 
               </p>
               <ul className="list-unstyled footer-sub-menu">
               <li className="f-16">
                   <Link  to={`${process.env.PUBLIC_URL}/about`} className="">
                    FarmKrisp
                   </Link>
                 </li>
                 <li className="f-16">
                   <Link  to={`${process.env.PUBLIC_URL}/why-us`} className="">
                   Why FarmKrisp
                   </Link>
                 </li>
                 <li className="f-16">
                   <Link  to={`${process.env.PUBLIC_URL}/privacy-policy`} className="">
                     Privacy Policy
                   </Link>
                 </li>
                 {/* <li className="f-16"><Link  to="/" className="">Blog</Link></li> */}
                 <li className="f-16">
                   <Link  to={`${process.env.PUBLIC_URL}/terms-of-use`} className="">
                     Terms of Use
                   </Link>
                 </li>
                 {/* <li className="f-16"><Link  to="/" className="">Life</Link></li> */}
                 {/* <li className="f-16">
                   <Link  to={`${process.env.PUBLIC_URL}/contact`} className="">
                     Contact
                   </Link>
                 </li> */}
                 
               </ul>
             </div>
           </Col>

           <Col lg="3" xs='6'>
             <div className="">
               <p className="text-uppercase  footer-title mb-0 ">
               Contact
               </p>
               <ul className=" footer-content list-unstyled footer-sub-menu mb-0">
                 <li className="f-16">
                 <p>
                 Ajeet Retail LLP  <br></br>
                 3rd floor, A-303, Elante Offices, Industrial Area, Phase 1,<br></br>
                 Chandigarh,  160002<br></br>
                 Email :-- info@farmkrisp.com <br></br>
                 Call :-- +91 7527000006
                 </p>
                 </li>
               </ul>
             </div>
             {/* <div>
               <p className=" text-uppercase  footer-title mb-0">
                 Get Social
               </p>
               
             </div> */}
           </Col>

             
        
      </Row>
     </Col>
             
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Footer;
