import React from 'react';
import Navbar from './components/Navbar';
import BuisnessFeatures from './components/BuisnessModel/BuisnessFeatures'
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt'
import BuisnessCarousel from './components/BuisnessModel/BuisnessCarousel';
import BuisnessFeaturesTwo from './components/BuisnessModel/BuisnessFeaturesTwo';
import BusinessFeatureThree from './components/BuisnessModel/BusinessFeatureThree';
import ImageJumbotron from './components/ImageJumbotron';


class BuisnessModal extends React.Component {

  componentDidMount() {
        document.getElementById("main_navbar").classList.add("navbar-light");
}

  render() {

    return (
      <React.Fragment>

        <Navbar />


        <ImageJumbotron headerImage='./images/bussiness.jpg' heading='FARMKRISP FRANCHISE' />

        <BuisnessFeatures />

        <BuisnessCarousel />

        <BuisnessFeaturesTwo />

        <BusinessFeatureThree />

        <Footer />

        <FooterAlt />
        

      </React.Fragment>

    );
  }
}

export default BuisnessModal;