import React from 'react';
import { Row, Col } from "reactstrap";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

class FooterAlt extends React.Component {
    render() {

        return (
            <React.Fragment>
                <hr className='m-0 footer-hr'></hr>
                <section className="footer-alt pt-3 pb-3" style={{backgroundColor:'#7DB036'}}>
                    <div className="container">
                    <Row>
                        <Col lg='4'>
                        <div className="text-left ">
                                <p className="copyright text-white f-14 font-weight-light mb-0"> {new Date().getFullYear()} © Copyrights 2020 Mohawk</p>
                            </div>
                        </Col>
                        <Col lg='4' className='text-center'>
                        
                        <div className="follow">
                    <ul className="follow-icon list-inline footer-alt-social mb-0">
                      <li className="list-inline-item f-18">
                        <a  href="https://www.facebook.com/FarmKrisp-114155743708702" target="_blank" rel="noopener noreferrer" className="social-icon font-blue font-blue ">
                          <FacebookIcon/>
                        </a>
                      </li>
                      &nbsp;
                      <li className="list-inline-item f-18">
                        <a  href="https://www.instagram.com/farmkrisp/" target="_blank" rel="noopener noreferrer" className="social-icon font-blue ">
                          <InstagramIcon />
                        </a>
                      </li>
                      &nbsp;
                      <li className="list-inline-item f-18">
                        <a  href="https://twitter.com/FarmKrisp" target="_blank" rel="noopener noreferrer" className="social-icon font-blue ">
                          <TwitterIcon />
                        </a>
                      </li>
                      &nbsp;
                      <li className="list-inline-item f-18">
                        <a  href="https://www.linkedin.com/company/farmkrisp" target="_blank" rel="noopener noreferrer" className="social-icon font-blue ">
                         <LinkedInIcon />
                        </a>
                      </li>
                     
                    </ul>
                  </div></Col>
                        <Col lg='4'>
                        <div className="text-right inline">
                                <p className="copyright text-white f-14 font-weight-light mb-0"> Designed By <a className='text-white' href='https://maxreachdigital.com/' rel="noopener noreferrer" target="_blank" ><u>MaxReach</u></a></p>
                            </div>
                        </Col>
                    </Row>
                       
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default FooterAlt;