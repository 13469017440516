import React from 'react'
import { Row, Col,} from "reactstrap";


export default function CovidNotice() {
    return (
        <div>
            <section className="section bg-light-green">
          <div className="container">
            <Row className="align-items-center covid-section">
                
                <Col lg="5" className='pl-0'>
                <h1 className='mb-0' style={{color:'#A1BA1A'}}>
                Post-Covid,</h1> <h1 className='heading mt-0'>what people are doing in the market?</h1> 
                
                </Col>
                <Row>
                    <Col lg='4'>
                    <p className="content font-weight-light">
                    The demand for fresh fruits & vegetables has increased from the beginning of COVID-19 pandemic and this has further highlighted two vital aspects of the supply chain: resilience & innovation.
                  </p>
                    </Col>
                    <Col lg='4'>
                    <p className="content font-weight-light">
                    There were unprecedented veggies & grocery sales in the wake of COVID-19 as shoppers are still rushing to stockpile to prepare for the unforeseen circumstances.
                  </p>
                    </Col>
                    <Col lg='4'>
                    <p className="content font-weight-light">
                    Vegetables are key driver of strong fresh produce & this sudden change in consumer sentiments on the onset of the pandemic is resulting in a much higher than normal demand.
                  </p>
                 
                    <div className="title-border-light"></div>
                    
                    </Col>
                </Row>
                
                  
                 
                   
              
            </Row>
          </div>
        </section>
        </div>
    )
}
