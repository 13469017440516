import React from 'react';

// import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Reasons from './components/WhyUs/Reasons'
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt'
import ReasonCard from './components/WhyUs/ReasonCard';
import Quote from './components/WhyUs/Quote';
import ImageJumbotron from './components/ImageJumbotron';


// import Footer from './components/Footer';
// import FooterAlt from './components/FooterAlt';


class WhyUs extends React.Component {

  componentDidMount() {
        document.getElementById("main_navbar").classList.add("navbar-light");
}

  render() {

    return (
      <React.Fragment>

        <Navbar />

        <ImageJumbotron headerImage='./images/why_us.jpg' heading='WHY FARMKRISP' />


        <Reasons />

        <ReasonCard />

        <Quote />

        <Footer />

        <FooterAlt />
        

      </React.Fragment>

    );
  }
}

export default WhyUs;