import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt'
import AboutFeature from './components/About/AboutFeature';
import AboutQuote from './components/About/AboutQuote';
import ImageJumbotron from './components/ImageJumbotron';




class About extends React.Component {

  componentDidMount() {
        document.getElementById("main_navbar").classList.add("navbar-light");
}

  render() {

    return (
      <React.Fragment>

        <Navbar />

        <ImageJumbotron headerImage='./images/about.jpg' heading='ABOUT FARMKRISP' />


        <AboutFeature />
       
        <AboutQuote />

        <Footer />

        <FooterAlt />
        

      </React.Fragment>

    );
  }
}

export default About;