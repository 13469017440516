import React from 'react'
import { Row, Col} from 'reactstrap';

export default function Quote() {
    return (
       
            <section className='section about-quote' >
                <div className='container'>
                    <Row className='text-center'>
                        <Col lg='12' className='center-mob'>
                        <span><img className='' alt='comma-left' style={{float:'left',width:'8%',marginLeft:'5rem'}} src='./images/comma1.svg' /></span>
                            <h1 className='bg-fnt text-left quote-font text-white mt-20 mb-0' style={{marginLeft:'14rem'}}>
                               Eat Healthy
                            </h1>
                            <h1 className='bg-fnt text-right quote-font-2  text-white mb-0' style={{marginRight:'9rem'}} >
                                 Stay Healthy
                            </h1>
                            <span className='ml-4' ><img alt='comma-right' className='comma-2' style={{float:'right',width:'8%',marginRight:'2rem'}} src='./images/comma.svg' /></span>
                        </Col>
                    </Row>
                </div>
            </section>
       
    )
}
