import React from 'react'
import { Row, Col,Card,CardBody,CardText,CardDeck } from 'reactstrap';


export default function AboutFeature() {
    const stats = [{
        'bgColor':'#5C401F',
        'content':'Horticulture contributes to 12.4% of the agriculture GDP of the state'
    },{
        'bgColor':'#BA821A',
        'content':'Extensive sector infrastructure supported by the Government'
    },{
        'bgColor':'#fe9900',
        'content':'Dedicated Research Centres'
    },{
        'bgColor':'#f97100',
        'content':'State Promotes Organic Farming'
    },{
        'bgColor':'#97bd00',
        'content':'Dedicated Agri Export Strategy'
    },{
        'bgColor':'#005e24',
        'content':'Attractive Fiscal Incentives'
    },{
        'bgColor':'#5aa100',
        'content':'Safety & Ease of Doing Business'
    },{
        'bgColor':'#ab0f0f',
        'content':'Peaceful & Competitive Labour'
    },{
        'bgColor':'#613f17',
        'content':'Quality Low Cost Power'
    },]

  const StatsTwo=[
        {
            'bgColor':'#f97100',
            'content':'FDI in Food Processing Sector is USD 8 Bn. (INR 5.6k Cr)'
        },{
            'bgColor':'#97bd00',
            'content':'GVA of Food Processing Sector is USD 27.4 Bn (INR 1.92 L Cr); CAGR 9%'
        },{
            'bgColor':'#BA821A',
            'content':'By 2030, India would be the 3rd largest consumer market'
        },{
            'bgColor':'#fe9900',
            'content':`By 2023, India's Food Retail to touch USD 827 Bn. Going up to 70% from 2017`
        }
    ]

 
    return (
        <div>
            <section className='section'>
            <div className='container'>
            <Row>
                <Col lg='6'>
                <div className='big-heading-2 font-weight-bold fnt-green'> Farmkrisp </div>
                <div className='title-border-caption '></div>
                <p className='mt-4 f-16 font-weight-light text-left'>
                Agro-Industry has always been an evergreen and thriving sector and in the new age it is providing bountiful opportunities to a myriad of businessmen, along with private and
                public agencies. So far, various boards and government institutions has made most out of this industry and seeing it's never ceasing demand, new age businessmen are also
                entering this area, making it even more promising, high in potential and organized.
                </p>
                <p className='mt-4 f-16 font-weight-light mb-4 text-left'>

                FarmKrisp is willing to take this opportunity and also benefit the society and its farmers. Coming from farming roots and attaining in-depth knowledge of agriculture, combined
                with experience in retail sector, FarmKrisp creates a unique business model that creates long lasting and profitable relationships.
                </p>
                </Col>
                <Col lg='6'>
                    <img className='img-fluid mb-mob' alt='about-img' src='./images/abouttt.svg' />
                </Col>
            </Row>
            </div>
            </section>

            <div className='granary-about' >
            <section className='section'>
            <Row>

            <Col lg='6'>
            <div className='big-heading font-weight-bold fnt-green pt-mob pl-1-mob l-1'>Granary</div>
            <div className='big-heading font-weight-bold fnt-green pl-1-mob l-1'>of India-</div>
            <div className='big-heading font-weight-bold text-white pl-1-mob l-1'>Punjab</div>
            <div className='big-heading font-weight-bold fnt-green pl-1-mob l-1'>leads the</div>
            <div className='big-heading font-weight-bold fnt-green pl-1-mob l-1'>way in</div>
            <div className='big-heading font-weight-bold fnt-green pl-1-mob l-1'>Agriculture</div>
            <div className='f-20 font-weight-light pb-mob pl-1-mob text-white mt-4'>As Punjab's wheat yield outperforms the world.</div>
            </Col>
            </Row>
            </section>
            </div>
            <section className='section'>
            <Row className='p2-mob ml-0-mob mr-0-mob'>
                <Col lg='6'>
                <div className="title-border-reason "></div>
                <div className='big-heading-2 fnt-green mt-4 l-1'>Centuries old backbone of the economy, now moving way forward! </div>
                </Col>
                <Col lg='6'>
                <div className='f-20 font-weight-light  mt-4'> Agriculture is an important part of India's economy and at present, it is among the top two farm producers in the world. This sector provides approximately 52% of the total number of jobs available in India, covers almost 43% of India's geographical area & contributes around 18.1 percent to the GDP.</div>
                </Col>
            </Row>
            </section>


            <section className='about-statistics'>
                <div className='container'>
                   
                    <div className='text-center text-mobile font-weight-bold text-white'>
                            STATISTICS
                    </div>
                    <Row className='text-center'>
                        
                    {StatsTwo.map((stat,index)=>{
                              return  <Col className='mt-4 ' lg='3' xs='6' key={index}>
                            <Card body className='reason-box-circle  mb-4' style={{backgroundColor:stat.bgColor}} >
                                      <CardBody className=' about-card-content pt-4 mt-2'>
                                        <CardText className='text-white stat-font mt-4 font-weight-light'>{stat.content}</CardText>
                                      </CardBody>
                            </Card>
                          </Col>
                        })}
                       
                    </Row>
                </div>
            </section>

            <section className='section'>
            <Row className='text-center about-card-style'>
                <Col lg='6'>
                <div className='vl mt-mob img-desktop img-center'> </div>
                <div className='big-heading fnt-green l-1 pt-4'>Agricultural</div>
                <div className='big-heading fnt-green l-1'>Statistics of</div>
                <div className='big-heading fnt-green l-1 pb-4'>Punjab</div>
                <div className='vl mb-mob img-desktop img-center'> </div>
                </Col>

                <Col lg='6'>
                <Row className='about-card-style'>
                <CardDeck>
                {stats.map((service, index) => {
                               
                            return <Col className='mr-0 pl-mob pr-0' lg='4' xs='12' key={index}>
                                    <Card body className='reason-box-three mr-0 mb-4' style={{backgroundColor:service.bgColor}} >
                                        <CardBody className='about-card-content'>
                                            <CardText className='f-16  text-white font-weight-light'>{service.content}</CardText>
                                        </CardBody>
                                    </Card>
                                  </Col>
                              })}
                              </CardDeck>
                               </Row>
                </Col>
            </Row>
            </section>
        </div>
    )
}
