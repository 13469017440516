import React from "react";
import ReactDOM from "react-dom";
import Home from "./Home";
import Gallery from "./Gallery";
import Contact from "./Contact";
import WhyUs from "./WhyUs";
import BuisnessModal from "./BuisnessModal";
import About from "./About";
import Team from "./Team";
import PrivacyPolicy from "./components/LegalPages/PrivacyPolicy";
import TermsOfUse from "./components/LegalPages/TermsOfUse";

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import FranchiseForm from "./FranchiseForm";
import { HashRouter } from "react-router-dom";

class Root extends React.Component {
  render() {
    return (
      <HashRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gallery`}
            component={Gallery}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/why-us`}
            component={WhyUs}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/franchise`}
            component={BuisnessModal}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/franchise-form`}
            component={FranchiseForm}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/team`}
            component={Team}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/privacy-policy`}
            component={PrivacyPolicy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/terms-of-use`}
            component={TermsOfUse}
          />

          <Route path={`*`} component={Home} />
        </Switch>
      </HashRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
