import React from 'react';
// import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import ImageGallery from './components/Gallery/ImageGallery'
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt'
import ImageJumbotron from './components/ImageJumbotron';

// import Footer from './components/Footer';
// import FooterAlt from './components/FooterAlt';


class Home extends React.Component {

  componentDidMount() {
        document.getElementById("main_navbar").classList.add("navbar-light");
}

  render() {

    return (
      <React.Fragment>

        <Navbar />


        <ImageJumbotron headerImage='./images/gallery.jpg' heading='FARMKRISP GALLERY' />

        <ImageGallery />

        <Footer />

        <FooterAlt />
        

      </React.Fragment>

    );
  }
}

export default Home;