import React from 'react'
import { Row, Col} from 'reactstrap';


export default function BuisnessFeaturesTwo() {
    const contentStyle = {
        height: '80vh',
        width:'100%',
        color: '#fff',
        backgroundSize:'cover',
        backgroundImage: `url("./images/Group_354.jpg")`,
        overflow: 'hidden',
          position: 'relative',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minWidth:'100%',
          minHeight: '100%'
      };
      
    return (
        <div>
            <section>
                <div className='container'>
                    <Row className='mt-mob'>
                    <Col lg='6' className='mt-4 pr-0 pt-4'>
                            <img className="img-fluid" alt='ecommerce farmkrisp' src='./images/Intersection_10.jpg' />
                        </Col>
                        <Col lg='6'>
                        <h1 className='fnt-green big-heading-2 mb-0 mt-4 pt-4'>FarmKrisp</h1>
                        <h1 className='fnt-green big-heading-2 mb-0'>E-Commerce</h1>
                        <div className="title-border-reason"></div>
                                    
                                    <div>
                                    <ul className='mt-4'>
                                    <li><h5 className='font-weight-light'>Web + App enabled for seamless customer experience </h5></li>
                                    <li><h5 className='font-weight-light'>Customers can choose between home delivery or pick from store options</h5></li>
                                    <li><h5 className='font-weight-light'>Notifications of promotions</h5></li>
                                    <li><h5 className='font-weight-light'>  Customised promotions & product suggestions on the basis of individual / family buying behaviour patterns</h5></li>
                                    <li><h5 className='font-weight-light'>Aids impulse & repeat purchase </h5></li>
                                    <li><h5 className='font-weight-light'> Promotes loyalty</h5></li>
                                    <li><h5 className='font-weight-light'>Perfect tool for customer education and brand building via content creation</h5></li>
                                    <li><h5 className='font-weight-light'>Omni Channel Integration</h5></li>
                                    <li><h5 className='font-weight-light'> Enables Geo targeting</h5></li>
                                    </ul>

                                    </div>
                        </Col>

                      
                    </Row>
                    <Row className='mb-4'>
                   
                        <Col lg='6'>
                        <h1 className='fnt-green big-heading-2 mt-4 pt-4 mb-0'>FarmKrisp</h1>
                        <h1 className='fnt-green big-heading-2 mb-0'>Mobile Vans</h1>
                        <div className="title-border-reason"></div>
                                    
                                    <div>
                                    <ul className='mt-4'>
                                    <li><h5 className='font-weight-light'>Housing clusters that have a dense population & spread out beyond a 3km radius will be serviced by a store & a mobile van</h5></li>
                                    <li><h5 className='font-weight-light'>This will ensure that customers can have easy access to our stores, within a walkable distance</h5></li>
                                    <li><h5 className='font-weight-light'>Hygiene & Safe</h5></li>
                                    <li><h5 className='font-weight-light'> Mobile Vans will be Wi Fi enabled with Mobile POS machines for easy billing at the right price </h5></li>
                                    <li><h5 className='font-weight-light'>All promotions will be available to customers shopping at all points of sale</h5></li>
                                    <li><h5 className='font-weight-light'> Store owner will have the option of owning the van as well, else will be offered to independent parties to run</h5></li>
                                    
                                    </ul>

                                    </div>
                        </Col>

                        <Col lg='6' className='pl-0'>
                            <img className="img-fluid" alt='mobile van' src='./images/Intersection_1313.jpg' />
                        </Col>
                    </Row>
                </div>
            </section>

          
                    <section className='section text-center' style={contentStyle}> 
                        <h1 className='big-heading text-white font-middle  pt-4 mb-0'>
                            How you can be a part of this
                        </h1>
                        <h1 className='big-heading text-white '>
                            New Green Revolution?
                        </h1>
                    </section>
                      
        </div>
    )
}
