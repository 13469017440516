import React from "react";
import { Row, Col,Card,CardBody,CardText,Button } from 'reactstrap';
import { Link } from "react-router-dom";


export default function Model() {
  const models = [
    {
      icon: "./images/Group_49-01.png",
      content: "Scientific Harvesting of naturally grown vegetables and fruits in our owned and contracted farms. Farmkrisp have access to 100+ acre of own farms and 1000+ acre of contracted farms across different geography of the state.",
      bgColor:'#005C2B'
    },
    {
      icon: "./images/box-2.png",
      content: "Post harvest Pre-Cooling Facility in refer vans, grading & packing of vegetables in a modern and world class packaging units with optimum nitro cold stores and processing capacity of 20+ Metric Tons per day.",
      bgColor:'#BA821A'
    },
    {
      icon: "./images/box3.png",
      content: "20+ FarmKrisp Stores will be launched in 1 Year, including Mobile Vans & Online Delivery supported by strong e-commerce to reach customers at every nook and corner of the Tri City.",
      bgColor:'#E87817'
    }
  ];
  return (
    <div>
      <section className="section bg-light-green">
        <div className="container mb-0">
        <Row>
          <Col>
          <h1 className='fnt-green pt-mob'>The FarmKrisp Model</h1>
          </Col>
          <div className="title-border-model"></div>
        </Row>
          <Row>
            {models.map((model, index) => {
              return (
                <Col lg="4" sm='6' md="6" key={index}>
                  <Card className="model-box text-center  mt-4" style={{backgroundColor:model.bgColor}}>
                    <div className="model-icon mt-3  ">
                      <img className="model-img" alt='model_logo' src={model.icon} />
                    </div>
                    <CardBody style={{ minHeight: "32vh" }}>
                      <CardText className="f-16 font-weight-light">
                        {model.content}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col className='text-center pb-mob'>
            <Link to={`${process.env.PUBLIC_URL}/why-us`}>
            
              <Button className='button-custom'>
                know more
              </Button>
            </Link>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}
