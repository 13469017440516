import React from "react";
import {NavLink, Link } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';

// import ScrollspyNav from './scrollSpy';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Tab: "" };
  }

  /**
   * Sets active tab
   */
  setActiveTab = (tab, e) => {
    this.setState({ Tab: tab });
  };

  render() {
    return (
      <React.Fragment>
        <nav
          id="main_navbar"
          className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark"
        >
          <div className="container-fluid navbar-bg">
            <Link className="navbar-brand logo-absolute logo" to={`${process.env.PUBLIC_URL}/`}>
            <img src='./images/logo.png' alt="company_logo" height="60" />
              {/* <img src="./images/study_revolution.png" alt="" height="40" /> */}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <MenuIcon />
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse" >
            
              <ul className="navbar-nav p2-mob ml-auto navbar-center" id="mySidenav">
              <li className="nav-item">
                  <NavLink
                      className="nav-link "
                      exact={true} 
                      activeClassName='is-active'
                      to={`${process.env.PUBLIC_URL}/`}
                    >
                       Home
                    </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      className="nav-link "
                      activeClassName='is-active'
                      to={`${process.env.PUBLIC_URL}/about`}
                    >
                       About
                    </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                      className="nav-link "
                      activeClassName='is-active'
                      exact={true}
                      to={`${process.env.PUBLIC_URL}/why-us`}
                    >
                       Why FarmKrisp
                    </NavLink>
                </li>

                <li className="nav-item ">
                  <NavLink
                      className="nav-link "
                      activeClassName='is-active'
                      to={`${process.env.PUBLIC_URL}/franchise`}
                    >
                       Franchise
                    </NavLink>
                </li>
                
                <li className="nav-item ">
                  <NavLink
                      className="nav-link"
                      activeClassName='is-active'
                      to={`${process.env.PUBLIC_URL}/team`}
                    >
                       Team
                    </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      className="nav-link "
                      activeClassName='is-active'
                      to={`${process.env.PUBLIC_URL}/gallery`}
                    >
                       Gallery
                    </NavLink>
                </li>
                

                {/* <li className="nav-item ">
                  <NavLink
                      className="nav-link "
                      activeClassName='is-active'
                      to={`${process.env.PUBLIC_URL}/franchise-form`}
                    >
                       Franchise Form
                    </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink
                      className="nav-link "
                      activeClassName='is-active'
                      to={`${process.env.PUBLIC_URL}/contact`}
                    >
                       Contact
                    </NavLink>
                </li>
                
                
              </ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Navbar;
