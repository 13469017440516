import React from 'react';
import { Row, Col,Card,CardBody,CardTitle,CardText } from 'reactstrap';
import { Element } from 'react-scroll'


class ReasonCard extends React.Component {


    render() {

        return (
            <React.Fragment>
                <section className="section" id="services">
                    <div className="container">
                    <h2 className='text-center heading mt-mob font-weight-light'>HOW TO PARTNER WITH FARMKRISP?</h2>
                        <Row className='text-center'>
                        <Col lg='2'></Col>
                        <div id='Foco-destination' name='foco-destination'>
                        
                                 <Col  lg='4'>
                                    <Card className='reason-box-two  mt-4' >
                                            <CardBody >
                                            <CardTitle className='f-24 reason-title-two font-weight-bold text-white text-left mb-4' >1.</CardTitle>
                                            <CardTitle className='f-24 reason-title-two font-weight-bold text-white text-left pt-0' >FOCO</CardTitle>
                                            <div className="title-border-white"></div>
                                            <CardText className='f-16 reason-two-content text-left text-white font-weight-normal'>FRANCHISEE OWNED COMPANY OPERATED</CardText>
                                            </CardBody>
                                        </Card>
                                </Col>
                        </div>
                                <Col lg='4' >
                                    <Card className='reason-box-two ml-4 mt-4 ' >
                                            <CardBody >
                                            
                                            <CardTitle className='f-24 reason-title-two font-weight-bold text-white text-left mb-4' >2.</CardTitle>
                                            <CardTitle className='f-24 reason-title-two font-weight-bold text-white text-left' >FOFO</CardTitle>
                                            <div className="title-border-white"></div>
                                            <CardText className='f-16 reason-two-content text-left text-white font-weight-normal'>FRANCHISEE OWNED FRANCHISEE OPERATED</CardText>
                                            </CardBody>
                                        </Card>
                                </Col>
                                <Col lg='2'></Col>

                                <Col lg='12' className='text-center mt-4'>
                                <h1 className='fnt-green font-weight-light'>Investment starts from Rs. 9 lacs.</h1>
                                </Col>
                                <Col lg='1'></Col>
                                <Col lg='5' className=' why-points text-left mt-4 pl-4'>
                                    <h2>Ideal Partner:</h2>
                                    <ul>
                                    <li>500-700 sq ft Store Space.</li>
                                    <li>9 Years Lease, with 3 Years Lock-in period.</li>
                                    <li>Capex for Interiors & Technology Approx Rs. 2500 per sq ft.</li>
                                    <li>Running capital to maintain stocks</li>
                                    </ul>
                                </Col>
                                <Col lg='6' className='why-points-2 why-points mt-4 text-left'>
                                    <h2>Farmkrisp to Provide:</h2>
                                    <ul>
                                    <li>Stock replenishment as per requirement.</li>
                                    <li>Marketing & Sales Planning.</li>
                                    <li>Operations, Technology, HR hiring & Training Support.</li>
                                    <li>Monthly Review, Feedback & Best Practices shared across the chain.</li>
                                    </ul>
                                </Col>
                        </Row>
                       
                       
                    </div>
                </section>

            </React.Fragment>
        );
    }
}
export default ReasonCard;