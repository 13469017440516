import React from 'react'
import { Row, Col, } from "reactstrap";


export default function AboutUs() {

 const sectionStyle={
    paddingBottom: '85px !important',
    paddingTop: '45px !important'
  }


    return (
        <div>
            <section style={sectionStyle} className="section">
          <div className="container-fluid pt-0" >
            <Row className="align-items-center">
                
                  <Col lg="6">
                <div className="mt-mob about-img">
                  {/* <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" /> */}
                  <img
                    src="images/farmer.jpg"
                    alt="farmer"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col>
                <Col className="pb-4 about-content-one" lg="6" >
                <div className='title-heading'>
                  <h4 className="mb-4 heading">
                 About Us
                  </h4>
                  <div className="title-border-simple"></div>
                </div>

                  <p className="content font-weight-light">
                  Agro-Industry has always been an evergreen and thriving sector and in the new age it is providing
                    bountiful opportunities to a myriad of businessmen, along with private and public agencies. So
                    far, various boards and government institutions has made most out of this industry and seeing
                    it's never ceasing demand, new age businessmen are also entering this area, making it even
                    more promising, high in potential and organized.
                  </p>
                  <p className="content mt-2 font-weight-light">
                  FarmKrisp is willing to take this opportunity and also benefit the society and its farmers. Coming
                    from farming roots and attaining in-depth knowledge of agriculture, combined with experience in
                    retail sector, FarmKrisp creates a unique business model that creates long lasting and profitable
                    relationships.
                  </p>
                  </Col>
                  
                 
                   
              
            </Row>
          </div>
        </section>
        </div>
    )
}
