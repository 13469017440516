import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt'
import ImageJumbotron from './components/ImageJumbotron';
import FranchiseRequest from './components/FranchiseForm/FranchiseRequest'



class FranchiseForm extends React.Component {

  componentDidMount() {
        document.getElementById("main_navbar").classList.add("navbar-light");
        document.getElementById("footer").classList.add("footerBorder");

}

  render() {

    return (
      <React.Fragment>

        <Navbar />

        <ImageJumbotron headerImage='./images/form.svg' heading='FRANCHISE REQUEST FORM' />

        <FranchiseRequest />

        <Footer />

        <FooterAlt />
        

      </React.Fragment>

    );
  }
}

export default FranchiseForm;