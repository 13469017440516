import React from 'react';
import { Row, Col,Card,CardBody,CardTitle,CardText } from 'reactstrap';


class Reasons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            keyReasonsOne: [{
                "bgColor": "#5C401F",
                "title": "1.",
                "content": "Opportunity to own a one-of-its kind Chain of Speciality Stores for Farm Fresh Produce in the Tri City Area.",
            }, {
                "bgColor": "#BA821A",
                "title": "2.",
                "content": "A Plug & Play Retail Store Format with Centralized Marketing & Operations Support, along with Trained Manpower.",
            }, {
                "bgColor": "#E87817",
                "title": "3.",
                "content": "Seamless knowledge transfer for store operations.",
            },{
                "bgColor": "#6E9E2B",
                "title": "4.",
                "content": "Investments starts from INR 9 Lacs with well-calculated & attractive ROI.",
            }, {
                "bgColor": "#005C2B",
                "title": "5.",
                "content": "Trusted & established resource network.",
            } 
            ],
            keyReasonsTwo:[
                 {
                    "bgColor": "#6E9E2B",
                    "title": "6.",
                    "content": "Uninterrupted, seamless and fully - integrated supply chain.",
                }, {
                    "bgColor": "#005C2B",
                    "title": "7.",
                    "content": "Sustainable Business Model as customers buy F&V every 4 days.",
                }, {
                    "bgColor": "#BA821A",
                    "title": "8.",
                    "content": "Assured walk-ins due to the rise in demand of naturally grown fruits & Vegetables.",
                }, {
                    "bgColor": "#5C401F",
                    "title": "9.",
                    "content": "Reputed Promoters with strong Agri Industry knowledge Real Estate ties & access to Global Resources.",
                },{
                    "bgColor": "#E87817",
                    "title": "10.",
                    "content": "Opportunity to be a part of a brand with Big Vision & Scalability.",
                },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section" id="services">
                    <div className="container">
                       
                        <h1 className='fnt-green mt-mob text-center'>10 Key Reasons</h1>
                        <h2 className='text-center heading font-weight-light'> Why FarmKrisp is an apt Business model?</h2>
                        <Row>
                            {this.state.keyReasonsOne.map((service, index) => {
                               
                             return    <Col lg='' xs='12'  key={index}>
                               
                                    <Card className='reason-box  mt-4' style={{backgroundColor:service.bgColor}} >
                                            <CardBody >
                                            <CardTitle className='f-24 reason-title font-weight-bold text-white text-left' >{service.title}</CardTitle>
                                            <CardText className='f-18 reason-content text-white font-weight-light'>{service.content}</CardText>
                                            </CardBody>
                                        </Card>
                                </Col>
                            })}
                        </Row>
                        <Row>
                            {this.state.keyReasonsTwo.map((service, index) => {
                               
                             return    <Col lg='' xs='12' key={index}>
                               
                                    <Card className='reason-box   mt-4' style={{backgroundColor:service.bgColor}} >
                                            <CardBody >
                                            <CardTitle className='f-16 reason-title font-weight-bold text-white text-left'>{service.title}</CardTitle>
                                            <CardText className='f-18 reason-content text-white font-weight-light'>{service.content}</CardText>
                                            </CardBody>
                                        </Card>
                                </Col>
                            })}
                        </Row>
                    </div>
                </section>

                <section className='section mt-mob bg-light-green'>
                <Row className='p2-mob mr-0-mob'>
                    <Col lg='6'>
                    <h1 className='fnt-green big-heading'>Technological Integration</h1>   
                    <div className="title-border-reason"></div>
                    </Col>

                    <Col lg='6'>
                    <h1 className='heading reason-content-two font-weight-light'>
                    End to End integration via Technology to give One System View from Farm to DC to all POS, including loyalty and CRM
                    </h1>
                    </Col>
                </Row>
                
                
                </section>

               
                   <section className='section process-section img-mobile-process' >
                  
                   <Row>

                   </Row>
                   
                   </section> 

                   <section className='section  img-desktop-process' >
                  <div className='container'>

                  <Row>
                        <Col lg='12' className='text-center '>
                        <img className='img-fluid' alt='reason_one_pic' src='./images/Group_156.jpg' />
                        </Col>
                  </Row>
                  </div>
                  
                  </section> 



                   <section className='section reason-part'>
                       <Row className='p2-mob mr-0-mob'>
                           <Col lg='12' className='text-center'>
                           <h1 className='fnt-green big-heading-2 mb-0'>NOW IS THE TIME TO JOIN THE </h1> 
                           <h1 className='fnt-green big-heading-2'> PROFITABLE GREEN REVOLUTION</h1>   
  
                    <div className="title-border-reason-two text-center"></div>
                    
                    <h2 className='text-white rem-3 font-weight-bold pt-4 mb-0'>Low Investments</h2>
                    <h2 className='text-white rem-3 font-weight-bold mt-0 pt-0'>High Returns</h2>

                           </Col>
                       </Row>
                    </section> 


            </React.Fragment>
        );
    }
}
export default Reasons;