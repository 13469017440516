import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt'
import TeamFeature from './components/Team/TeamFeature';
import TeamMember from './components/Team/TeamMember';
import ImageJumbotron from './components/ImageJumbotron';




class WhyUs extends React.Component {

  componentDidMount() {
        document.getElementById("main_navbar").classList.add("navbar-light");
}

  render() {

    return (
      <React.Fragment>

        <Navbar />

        <ImageJumbotron headerImage='./images/team.jpg' heading='FARMKRISP TEAM'  />

        <TeamFeature />

        <TeamMember />
        
        <Footer />

        <FooterAlt />
        

      </React.Fragment>

    );
  }
}

export default WhyUs;