import React from "react";
import { Row, Col, Button, Alert } from "reactstrap";
import axios from "axios";

import { Link } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
const API_PATH = "//www.farmkrisp.com/form.php";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);

    this.state = {
      franchiseState: false,
      commercialStatus: false,
      status: false,
    };
  }

  onDismiss() {
    this.setState({
      status: false,
    });
  }

  handleValidSubmit(event, values) {
    const body = {
      name: values.Name,
      email: values.Email,
      number: values.Number,
      city: values.City,
      occupation: values.Occupation,
      ownedFranchise: values.ownedFranchise,
      franchiseName: values.franchiseName,
      ownedLocation: values.ownedLocation,
      spaceLocation: values.spaceLocation,
      spaceArea: values.SpaceArea,
      certify: values.certify,
    };

    console.log(values);

    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: body,
    })
      .then((result) => {
        this.setState({
          status: true,
        });

        this.form && this.form.reset();
      })
      .catch((error) => this.setState({ error: error.message }));
    console.log(body);

    const form = document.forms["franchise-form"];

    fetch(
      `https://script.google.com/macros/s/AKfycbxGjUmFeTxp09rl_6mFqAg00A5dcRM0mWRYkEMgcfEzhraIJh4l/exec`,
      { method: "POST", body: new FormData(form) }
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            status: true,
          });
          this.form && this.form.reset();
        }
      })
      .catch((error) => console.error("Error!", error.message));
  }

  handleFranchiseStatus = (e) => {
    let selectedValue = e.target.value;
    if (selectedValue === "Yes") {
      this.setState({
        franchiseState: true,
      });
    } else if (selectedValue === "No") {
      this.setState({
        franchiseState: false,
      });
    }
  };

  handleCommercialStatus = (e) => {
    let selectedValue = e.target.value;
    if (selectedValue === "Yes") {
      this.setState({
        commercialStatus: true,
      });
    } else if (selectedValue === "No") {
      this.setState({
        commercialStatus: false,
      });
    }
  };

  render() {
    const defaultValues = {
      ownedFranchise: "No",
      ownedLocation: "No",
      Occupation: "",
    };

    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <Col lg="12" className="text-center mb-4">
              <h1 className="mb-4 mt-mob green-heading">
                FRANCHISE REQUEST FORM
              </h1>
              <div className="title-border-franchise"></div>
            </Col>
            <Row className="ml-0-mob mr-0-mob">
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                name="franchise-form"
                model={defaultValues}
                ref={(c) => (this.form = c)}
              >
                <Row className="pt-4 mt-4">
                  <Col lg="6">
                    <div className="form-group   app-label ">
                      <AvField
                        name="Name"
                        className="form-radius"
                        type="text"
                        validate={{ required: { value: true } }}
                        label="Full Name*"
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group   app-label ">
                      <AvField
                        name="Email"
                        className="form-radius"
                        type="text"
                        validate={{ email: true, required: true }}
                        label="Email*"
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="form-group   app-label ">
                      <AvField
                        name="Number"
                        className="form-radius"
                        type="text"
                        validate={{ required: { value: true } }}
                        label="Phone Number*"
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group   app-label ">
                      <AvField
                        name="City"
                        className="form-radius"
                        type="text"
                        validate={{ required: { value: true } }}
                        label="City*"
                      />
                    </div>
                  </Col>

                  <Col lg="12" className="text-center">
                    <h1 className="fnt-green f-20 mt-4 mb-0">
                      Business Experience
                    </h1>
                  </Col>

                  <Col lg="6">
                    <div className="form-group app-label ">
                      <AvField
                        type="select"
                        name="Occupation"
                        required
                        label="Current Occupation"
                      >
                        <option value="" disabled selected>
                          Select your Occupation
                        </option>
                        <option>Service</option>
                        <option>Business</option>
                        <option>Other</option>
                      </AvField>
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="form-group app-label mb-0 franchise-status ">
                      <div className="inline">
                        <h6 className="inline">Ever Owned Franchise?</h6>
                      </div>
                      <AvRadioGroup
                        inline
                        className="inline mb-0 ml-4"
                        name="ownedFranchise"
                      >
                        <AvRadio
                          customInput
                          label="Yes"
                          onClick={this.handleFranchiseStatus}
                          value="Yes"
                        />
                        <AvRadio
                          customInput
                          label="No"
                          onClick={this.handleFranchiseStatus}
                          value="No"
                        />
                      </AvRadioGroup>
                    </div>
                  </Col>

                  {this.state.franchiseState ? (
                    <Col lg="6">
                      <div className="form-group app-label ">
                        <AvField
                          name="franchiseName"
                          className="form-radius"
                          type="text"
                          validate={{ required: { value: true } }}
                          label="Franchise Name"
                        />
                      </div>
                    </Col>
                  ) : (
                    <Col lg="6">
                      <div className="form-group   app-label ">
                        <AvField
                          name="franchiseName"
                          disabled
                          className="form-radius"
                          type="text"
                          label="Franchise Name"
                        />
                      </div>
                    </Col>
                  )}

                  <Col lg="12" className="text-center">
                    <h1 className="fnt-green f-20 mt-4 mb-0">Location Info</h1>
                  </Col>

                  <Col lg="12">
                    <div className="form-group app-label mt-4   ">
                      <h6 className="inline">Do you Own a commercial space?</h6>
                      <AvRadioGroup
                        inline
                        className="inline mb-0 ml-4"
                        name="ownedLocation"
                      >
                        <AvRadio
                          customInput
                          onClick={this.handleCommercialStatus}
                          label="Yes"
                          value="Yes"
                        />
                        <AvRadio
                          customInput
                          onClick={this.handleCommercialStatus}
                          label="No"
                          value="No"
                        />
                      </AvRadioGroup>
                    </div>
                  </Col>

                  {this.state.commercialStatus ? (
                    <>
                      {" "}
                      <Col lg="6">
                        <div className="form-group   app-label ">
                          <AvField
                            name="spaceLocation"
                            className="form-radius"
                            type="text"
                            validate={{ required: { value: true } }}
                            label="Space Location"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group   app-label ">
                          <AvField
                            name="SpaceArea"
                            className="form-radius"
                            type="text"
                            validate={{ required: { value: true } }}
                            label="Space Area"
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col lg="6">
                        <div className="form-group   app-label ">
                          <AvField
                            name="spaceLocation"
                            disabled
                            className="form-radius"
                            type="text"
                            label="Space Location"
                          />
                        </div>
                      </Col>

                      <Col lg="6">
                        <div className="form-group   app-label ">
                          <AvField
                            name="SpaceArea"
                            disabled
                            className="form-radius"
                            type="text"
                            label="Space Area"
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  <Col lg="12" className="text-center">
                    <div className="form-group app-label">
                      <AvField
                        type="checkbox"
                        name="certify"
                        validate={{ required: { value: true } }}
                        label="By Submiting this form I certify that the information furnished in this FarmKrisp franchise Request for consideration is true and correct"
                      />{" "}
                    </div>
                  </Col>

                  <Col lg="12" className="text-center mb-mob">
                    <Button type="submit" className="contact-button">
                      Submit
                    </Button>
                  </Col>
                  <Col lg="12" className="text-center mt-4">
                    {this.state.status ? (
                      <Alert toggle={this.onDismiss} color="success">
                        Our team will contact you soon!
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
              </AvForm>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Contact;
