import React from 'react'
import { Row, Col} from 'reactstrap';

export default function TeamMember() {
  const  teamMembersOne=[{
        'imageUrl':'./images/Group_358.svg',
        'name':'Jatinder Pal Singh',
        'designation':'Chairman - Ajeet Retail LLP',
        'detail':'A Passionate Agriculturalist, Real Estate Developer & Fashion Retailer, with businesses across North India and Canada, he is the first generation farmer, who strongly believes in empowering the farmers of Punjab and bringing global & modern agricultural practices & technology into progressive farming.'
    },{
        'imageUrl':'./images/Group_359.svg',
        'name':'Gunkaran Singh',
        'designation':'MD - Ajeet Retail LLP',
        'detail':'An MBA from Pepperdine Graziadio Business School and Entrepreneur at heart, Gunkaran Singh has built multiple businesses across Hospitality, Real Estate, Retail & Wealth Management, including a state of the art apple orchard in Canada. Being a visionary, Gunkaran believes in the economical progress and high potential of the Tri City and would like to create value in his home State.'
    }]
    const  teamMembersTwo=[{
        'imageUrl':'./images/Group_360.svg', 
        'name':'Dr. Vivyek Rai',
        'designation':'Principal Advisor - Ajeet Retail LLP',
        'detail':'With years of experience in Operations, Marketing, Retail, both in India & Abroad, Dr. Vivyek will be responsible for Business Development, Operations & Profitability of both the Retail & Distribution Centre of Farmkrisp. His self discipline nature & military training allows him to wear multiple hats with ease & excel under all circumstances.'
    },{
        'imageUrl':'./images/Group_361.svg',
        'name':'Chetna Sodhi',  
        'designation':'Lead Architect',
        'detail':'Chetna Sodhi is a designer and business director for Architectia. She is responsible for designing several high-profile buildings and has also master-planned various mixed-use commercial and residential projects in the Firozpur, Amritsar and Chandigarh, her first project being an innovative new Law College for Guru Nanak Education Society in a 5 acre site in Ferozepur. She has studied Architecture at the Department of Architecture, Guru Nanak Dev University in Amritsar and has also worked with Celebrity Designer Varun Bahl as a Space Planner in Aurum.'
    }]
    const    teamMembersThree=[{
        'imageUrl':'./images/Group_362.svg',
        'name':'Riyaz Marol',
        'detail':`An MBA with over twenty years of hands-on experience in Organised Retail started as sales professional in a FMCG firm - Hindustan Lever & Godrej. Later on, he worked at Senior levels with International Retail giants like Metro Cash & Carry and India's prominent retail chain Future Group. He has also handled regional super market chain "Namdhari's Fresh". Riyaz Marol's excels in establishing profitable & scalable retail business models.`
    },{
        'imageUrl':'./images/Group_363.svg',
        'name':'Zarine Ninan',
        'detail':'An Omni Channel Evangelist, with fifteen years of Retail Experience, she is an Engineer MBA, managed both online and offline businesses across categories for Crossword Bookstores & Shoppers Stop. She has also leaded the strategic partnership between Shoppers Stop and Amazon India. In her last stint, she was leading the Estee Lauder Beauty business at Shoppers Stop, which included running over 150 POS across Speciality, Department and Stand Alone retail stores in the country.'
    }]                      
    return (
        <div>
           <section className='section' >
                <div className='container'>
                    <Row>

                        <Col lg='12'>
                        <div className='fnt-green big-heading-2 l-1'>Men with</div>
                        <div className='fnt-green big-heading-2 l-1 inline'>Vision & Mission</div>
                        <div className="title-border-full" style={{backgroundColor:'#A1BA1A'}}></div>
                        </Col>
                        {teamMembersOne.map((member,index)=>{
                            return <>
                            <Col key={index} lg='4' className='mt-4 pr-0 text-center'>
                        <img src={member.imageUrl} alt='team_one_member' style={{width:'70%'}} /> 
                        </Col>
                        <Col lg='8' className=' pl-0 team-member-section'>
                        <div className='fnt-green font-weight-bold f-20 inline'>{member.name}</div>
                        <div className='font-weight-bold f-20 inline mob-block ml-4'>{member.designation}</div>
                            <p className='f-16 font-weight-light'>
                            {member.detail}
                            </p>

                        </Col>
                            </>
                        })}
                        
                    </Row>
                    </div>
            </section>

            <section className='section' >
                <div className='container'>
                    <Row>

                        <Col lg='12'>
                        <div className='fnt-brown big-heading-2 l-1'>Senior</div>
                        <div className='fnt-brown big-heading-2 l-1 inline'>Management Team</div>
                        <div className="title-border-full" style={{backgroundColor:'#c38000'}}></div>
                        </Col>
                        {teamMembersTwo.map((member,index)=>{
                            return <>
                            <Col key={index} lg='4' className='mt-4 pr-0 text-center'>
                        <img src={member.imageUrl} alt='Team_two_member' style={{width:'70%'}} /> 
                        </Col>
                        <Col lg='8' className=' pl-0 team-member-section' >
                        <div className='fnt-brown font-weight-bold f-20 inline'>{member.name}</div>
                        <div className='font-weight-bold f-20 inline mob-block ml-4'>{member.designation}</div>
                            <p className='f-16 font-weight-light' >
                            {member.detail}
                            </p>

                        </Col>
                            </>
                        })}
                        
                    </Row>
                    </div>
            </section>

            <section className='section' >
                <div className='container'>
                    <Row>

                        <Col lg='12'>
                        <div className='fnt-orange big-heading-2 l-1'>The</div>
                        <div className='fnt-orange big-heading-2 l-1 inline'>Consultants</div>
                        <div className="title-border-full" style={{backgroundColor:'#f97100'}}></div>
                        </Col>
                        {teamMembersThree.map((member,index)=>{
                            return <>
                            <Col key={index} lg='4' className='mt-4 pr-0 text-center'>
                        <img src={member.imageUrl} alt='team_three_member' style={{width:'70%'}} /> 
                        </Col>
                        <Col lg='8' className=' pl-0 team-member-section'>
                        <div className='fnt-orange font-weight-bold f-20 inline'>{member.name}</div>
                            <p className='f-16 font-weight-light'>
                            {member.detail}
                            </p>

                        </Col>
                            </>
                        })}
                        
                    </Row>
                    </div>
            </section>
        </div>
    )
}
