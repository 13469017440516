import React from 'react'
import { Row, Col } from "reactstrap";


export default function ImageJumbotron(props) {

    const bgImage={
        backgroundImage:`url(${props.headerImage})`
    }
    return (
        <>
                <div className=' main-section container-fluid pl-0 pr-0 text-center' style={bgImage}>
                    <Row className='no-gutters'>
                        <Col lg='12 te'>
                        <div className='main-heading text-center'>{props.heading} </div>  
                        </Col>
                    </Row>
                   
                </div>
           
        </>
    )
}
