import React from "react";
import { Row, Col,Button } from "reactstrap";
import {Link } from "react-router-dom";

export default function BusinessFeatureThree() {
  
  const liStyle = {
    marginLeft: "3rem",
    display: "inline-block",
  };

  const fruitBg = {
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minWidth: "100%",
    minHeight: "100%",
  };
  return (
    <div>
      <section className="section">
      <div className='container'>
        <Row>
          <Col lg="12" className="text-center  ">
            <h1 className="fnt-green big-heading  mb-0">Business Model</h1>
            <div className="title-border-center text-center"></div>
          </Col>
        </Row>
      </div>
      </section>

      <section className="section business-section-one">
        <Row>
          <Col lg="3" xs='12' className="mobile-pic">
            <img
            alt='business model-1'
              className="model-logo"
              src="./images/Group_356.svg"
            />
          </Col>
          <Col lg="7">
            <div className="fnt-green big-heading-2 text-right">
              The Franchisee Business Model - 1
            </div>
            <h3 className="fnt-green text-right big-heading-3">
              Franchisee Owned Company Operated
            </h3>
          </Col>
        </Row>
      </section>
      <div className="container">
        <section className="section">
          <Row>
            <Col lg="12">
              <div className="feature-li">
                <ul className="mt-4">
                  <h2 className=" pl-0">Franchise To Provide:</h2>
                  <li>
                    {" "}
                    500 - 700 sq ft Store Location in keeping with the expansion
                    plans of the Franchisor
                  </li>
                  <li> 9 Years Lease. with 3 Years Lock in period </li>
                  <li>
                    {" "}
                    Capex for Interiors & Technology - Approx Rs.2500 per sq ft{" "}
                  </li>
                </ul>

                <ul className="mt-4">
                  <h2>Company To Provide:</h2>
                  <li> Day to day running of the store</li>
                  <li> Op-ex, Stocks & Working Capital </li>
                  <li> Store Team & Cluster Manager</li>
                  <li>
                    {" "}
                    Brand, SOP's, Knowledge transfer, Manpower Training &
                    Centralized Technology integration{" "}
                  </li>
                  <li> All Marketing Support & Distribution Support</li>
                  <li>
                    {" "}
                    All Licenses. Taxation legal compliances to Be borne by the
                    Company{" "}
                  </li>
                </ul>
              </div>
              <h2 className="fnt-green pl-2 font-weight-light">
                {" "}
                Proposed Pay-out to franchise @ 8% per month.*
              </h2>
            </Col>
           <Col lg='12' >
           <Link to={`${process.env.PUBLIC_URL}/franchise-form`} >
                                        <Button className='franchiseBtn mb-mob mt-4'>
                                            Own FarmKrisp Franchise
                                        </Button>
                                    </Link>
           </Col>
          </Row>
        </section>
      </div>

      <section className="section business-section-one">
        <Row>
          <Col lg="3" className="mobile-pic">
            <img
               className="model-logo-two"
              alt='business model-2'
              src="./images/Group_355.svg"
            />
          </Col>
          <Col lg="7">
            <div className="fnt-green big-heading-2 text-right">
              The Franchisee Business Model - 2
            </div>
            <h3 className="fnt-green text-right big-heading-3">
              Franchisee Owned Franchisee Operated
            </h3>
          </Col>
        </Row>
      </section>
      <div className="container">
        <section className="">
          <Row>
            <Col lg="12" className="pt-4">
              <div className="feature-li mt-4">
                <ul className="mt-4">
                  <h2 className=" pl-0">Franchise To Provide:</h2>
                  <li>
                    {" "}
                    500 - 700 sq ft Store Location in keeping with the expansion
                    plans of the Franchisor
                  </li>
                  <li> 9 Years Lease. with 3 Years Lock in period </li>
                  <li>
                    {" "}
                    Capex for Interiors & Technology Approx Rs.2500 per sq ft{" "}
                  </li>
                  <li>Non Refundable Franchisor Fee of Rs 3 Lacs</li>
                  <li>Day to day running of the store </li>
                  <li> Op-ex including staff salaries, promotional expenses</li>
                  <li>Working Capital</li>
                </ul>

                <ul className="mt-4">
                  <h2>Company To Provide:</h2>
                  <li> Stocks as per replenishment requirements </li>
                  <li>
                    Marketing, Operations, Technology & HR Hiring & Training
                    Support{" "}
                  </li>
                </ul>
              </div>
              <h2 className="fnt-green pl-2 font-weight-light">
                {" "}
                Royalty to Franchisor @ 4% per month + 4% for Operational
                Support
              </h2>
            </Col>
            <Col lg='12'>
           <Link to={`${process.env.PUBLIC_URL}/franchise-form`} >
                                        <Button className='franchiseBtn mb-4  mt-4'>
                                            Own FarmKrisp Franchise
                                        </Button>
                                    </Link>
           </Col>
          </Row>
        </section>
      </div>

      
        <section className="bg-light-green" id={'foco-section'}>
        <div className="container ">
          <Row>
            <Col lg="12">
              <div  className="fnt-green mb-4 mb-0-mob big-heading-2 pt-10">
                Pre-Launch Support for FOCO
              </div>
            </Col>

            

            <Col lg="12">
              <div className="feature-three-li">
                <img
                  src="./images/Group_357.svg"
                  className="imageStyle"
                  alt='support'
                />

                
                <ul className="mt-4" style={liStyle}>
                  <li>
                    {" "}
                    Store design & Planogram Module, customised to the location
                  </li>
                  <li>Fixtures & Equipment List with best rates indicators</li>
                  <li>
                    {" "}
                    Construction to be done by Authorised Vendors of Franchisor{" "}
                  </li>
                  <li>
                    Assortment Plan for optimum stocking for maximum returns and
                    minimum stock outs
                  </li>
                  <li>
                    Centralised Software Integration for end-to-end visibility
                    and tracking
                  </li>
                  <li> Hardware Recommendations </li>
                  <li>
                    Visual Merchandising Tool Kit for best in store customer
                    experience
                  </li>
                  <li>
                    Pricing & Promotion Strategy and over all knowledge sharing
                    of schemes that worked across the chain
                  </li>
                  <li>
                    SOP for Store Operations with Efficiency & Hygiene focus
                  </li>
                  <li>Training of Staff to ensure minimum wastage</li>
                  <li> Sales Projections, Budgeting & P&L</li>
                  <li>Reporting & Retail KPI Benchmarking & Tracking </li>
                  <li>Store Launch Tool kit</li>
                </ul>
              </div>
            </Col>
          </Row>
          </div>
        </section>
     

     
        <section className="bg-light-green pb-0" id={'fofo-section'}>
        <div className="container">
          <Row>
            <Col lg="12">
              <div className="fnt-green mb-4 mb-0-mob big-heading-2 pt-10">
                Pre-Launch Support for FOFO
              </div>
            </Col>

            <Col lg="12">
              <div className="feature-three-li">
                <img
                  src="./images/Group_357.svg"
                  alt='fofo model'
                  className="imageStyle img-fluid"
                />
                <ul className="mt-4" style={liStyle}>
                  <li>
                    {" "}
                    Maintenance of ideal Stock levels & Daily Replenishment to
                    ensure freshness of produce is maintained{" "}
                  </li>
                  <li>
                    Ensuring operational agility by keeping close check on
                    inventory thereby ensuring minimum wastage
                  </li>
                  <li>
                    Analytics tools at the back end to support operational
                    efficiencies & customer satisfaction{" "}
                  </li>
                  <li>Operational hand holding for the first 2 months </li>
                  <li>A Cluster Manager overseeing daily operations</li>
                  <li>
                    {" "}
                    Optimization of all processes based on customer response
                    within first 2 months of opening
                  </li>
                  <li>Central Marketing Support with Promotions and Events</li>
                  <li>
                    E-commerce integration so that customers reach us on App/
                    Responsive Brand Website/ Marketplaces
                  </li>
                  <li>
                    Boosting Omni Channel touch points for a seamless customer
                    experience from order to delivery
                  </li>
                  <li>
                    Monthly Review, Feedback & Best Practices shared across the
                    chain{" "}
                  </li>
                  <li>
                    {" "}
                    First preference for ownership of Mobile van for the cluster
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          </div>
        </section>
     

<section>
    <div className='container-fluid'>

      <Row>
        <Col lg="12" className='pr-mob pl-0 pr-0 pl-mob'>
          <img
            className="img-fluid"
            alt='fruit background'
            style={fruitBg}
            src="./images/Group_328.jpg"
          />
        </Col>
      </Row>
    </div>
</section>
    </div>
  );
}
