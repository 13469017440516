import React from 'react'
import { Row, Col } from "reactstrap";


export default function Process() {
    const steps = [{
        numberImg:'./images/1th_process.webp',
        content:`Growing ingredients, while working with farmers who are well-versed with traditional & contemporary farming techniques, helping customers know exactly what is produced from very start, while empowering locals with better farming methods, territory, lifestyle & economy.`
    },{
        numberImg:'./images/2nd_process.webp',
        content:`Crops are harvested by skilled
        labourers and the handling process
        takes place on site. eliminating the
        need of lengthy transport & storage
        logistics. It further gives an insight on
        how crops and orchards are doing,
        ensuring their freshest point.`
    },{
        numberImg:'./images/3rd_process.svg',
        content:`Processing is done in an on-site
        processing plant, maintaining the
        purity and nutrition of the raw
        ingredients, ensuring complete health
        benefits.`
    },{
        numberImg:'./images/4th_process.webp',
        content:`Packaging also takes place on site,
        preserving the integrity and freshness
        of the products. All product
        information is also detailed on the
        packing.`
    },{
        numberImg:'./images/5th_process.webp',
        content:`You can get your fruits & veggies
        directly from the location through
        online orders or you can visit the store
        to pick the fresh produce on your own.`
    },]
    return (
        <div>
            <section className="section">
          <div className="container">
            <Row className="align-items-center">
                
                  <Col lg="6" xs='6' className="process-img-section">
                <div className="process-img mt-4">
                  {/* <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" /> */}
                  <img
                    src="images/PROCESSS.svg"
                    alt="process_img"
                    className="img-fluid mx-auto d-block img-process-main"
                  />
                </div>
                </Col>
                <Col className="pb-4" lg="6" xs='6' >
                {steps.map((step,i)=>{
                    return <Col key={i} className='process-points'> 
                        <div  className="process-point-img mt-4">
                  {/* <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" /> */}
                  <img
                    src={step.numberImg}
                    alt="process_points"
                    className="img-fluid mx-auto d-block "
                  />
                </div>

                  <p className="process-content font-weight-light" style={{}}>
                  {step.content}
                  </p>
                    </Col>
                })}
                
                  
                 
                  </Col>
                  
                 
                   
              
            </Row>
          </div>
        </section>
        </div>
    )
}
