import React from 'react';

// import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Jumbotron from './components/Jumbotron';
import AboutUs from './components/AboutUs';
import CovidNotice from './components/CovidNotice';
import FarmToFork from './components/FarmToFork';
import Process from './components/Process';
import Model from './components/Model';
import AboutSection from './components/AboutSection';
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt'

// import Footer from './components/Footer';
// import FooterAlt from './components/FooterAlt';


class Home extends React.Component {

  componentDidMount() {
        document.getElementById("main_navbar").classList.add("navbar-light");
}

  render() {

    return (
      <React.Fragment>

        <Navbar />

        <Jumbotron />

        <AboutUs />

        <CovidNotice />

        <FarmToFork />

        <Process />

        <Model />

        <AboutSection />

        <Footer />

        <FooterAlt />
        

      </React.Fragment>

    );
  }
}

export default Home;