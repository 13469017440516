import React from 'react'
import { Row, Col} from 'reactstrap';


export default function TeamFeature() {
    
      
    return (
        <div>
            <section className='section team-feature-one' >
                <div className='container'>
                    <Row>
                        <Col lg='5'>
                        <div className='big-heading green-heading l-1'>The</div>     
                        <div className='big-heading semi-green l-1'>FarmKrisp</div>                        
                        <div className='big-heading fnt-green l-1'>Story</div>                        
                   
                        </Col>

                        <Col lg='6'>
                       <p className='rem-2 font-weight-light' >
                           
                       With the aim to take fresh produce from our farms to your table, Farmkrisp integrates Value Chain to bring to our customers a Fruit & Veggie Destination Store, offering a world class customer experience, at affordable prices
                        </p>                       
                        <div className="title-border-reason"></div>

                        </Col>
                    </Row>
                    </div>
            </section>
            
        </div>
    )
}
