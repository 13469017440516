import React from 'react'
import { Row, Col,Button} from 'reactstrap';
import { Link } from "react-router-dom";

 
export default function BuisnessFeatures() {
    return (
            <section>
                <div className='container'>
                    <Row className='mt-4'>
                        <Col lg='6'>
                        <h1 className='fnt-green big-heading-2 mb-0'>FarmKrisp</h1>
                        <h1 className='fnt-green big-heading-2 mb-0'>Stores</h1>
                        <div className="title-border-reason"></div>
                                    <ul className='mt-4'>
                                    <li><h5 className='l-1'>Fruits & Vegetable Store</h5></li>
                                    <li><h5 className='l-1'>Dairy & Mini Bakery</h5> </li>
                                    <li><h5 className='l-1'>Juice/Smoothie Bar, Hydroponics & Customized Chopping Station</h5></li>
                                    </ul>
                        <div className="title-border-reason mb-4" ></div>
                                    <div>
                                    <ul className='mt-4'>
                                    <li><h5 className='font-weight-light'>5oo Sq feet of World Class Retail Experience </h5></li>
                                    <li><h5 className='font-weight-light'>Clean & Hygienic </h5></li>
                                    <li><h5 className='font-weight-light'>Fresh & Organized Assortment  </h5></li>
                                    <li><h5 className='font-weight-light'>Knowledgeable & Friendly Staff </h5></li>
                                    <li><h5 className='font-weight-light'> Family experience</h5></li>
                                    <li><h5 className='font-weight-light'>Air Conditioned & Sanitized </h5></li>
                                    <li><h5 className='font-weight-light'> Pocket Friendly Pricing</h5></li>
                                    </ul>
                                    </div>

                                    <Link to={`${process.env.PUBLIC_URL}/franchise-form`} >
                                        <Button className='franchiseBtn mb-mob mt-4 mb-4'>
                                            Own FarmKrisp Franchise
                                        </Button>
                                    </Link>
                        </Col>

                    

                        <Col lg='6' className='mb-4 text-right'>
                            <img className="img-fluid"  alt='store' src='./images/FarmKrisp_5_1.jpg' />
                        </Col>
                    </Row>
                </div>
            </section>
    )
}
