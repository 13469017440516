import React from 'react'
import { Row, Col } from "reactstrap";


export default function ImageGallery() {

    return (
        <div>
            <section className="section">
          <div className="container">
            <Row className="align-items-center">
            <Col lg='12' className='text-center'>
                <h1 className="mb-4 green-heading">
                Images of Store
                </h1>
                <div className="title-border-gallery"></div>

            </Col>
                
                  <Col lg="6" className='gallery-img' >
                <div className="about-img mt-4">
                  {/* <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" /> */}
                  <img
                    src="images/FarmKrisp_1.jpg"
                    alt="Store one"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col>
                <Col lg="6" className='gallery-img'>
                <div className="about-img mt-4">
                  {/* <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" /> */}
                  <img
                    src="images/FarmKrisp_2.jpg"
                    alt="Store Two"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col>
                <Col lg="6" className='gallery-img'>
                <div className="about-img mt-4">
                  {/* <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" /> */}
                  <img
                    src="images/FarmKrisp_4.jpg"
                    alt="Store Three"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col>
                <Col lg="6" className='gallery-img'>
                <div className="about-img mt-4">
                  {/* <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" /> */}
                  <img
                    src="images/FarmKrisp_3.jpg"
                    alt="Store four"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col>
                <Col lg="12" className='gallery-img text-center'>
                <div className="about-img mt-4">
                  {/* <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" /> */}
                  <img
                    src="images/FarmKrisp_5_1.jpg"
                  
                    style={{width:'30%'}}
                    alt="Store four"
                    className="img-fluid gallery-img-last mx-auto d-block"
                  />
                </div>
                </Col>
            </Row>
            </div>
            </section>

            <section className="section pt-0">
          <div className="container pt-0">

          <Row className="align-items-center text-center pt-0">
            <Col lg='12'>
                <h1 className="mb-4 green-heading">
                Image of Mobile Van
                </h1>
                <div className="title-border-gallery"></div>
            </Col>
                
                <Col lg="12" className='gallery-img text-center'>
                <div className="about-img mt-4">
                  <img
                    src="images/Intersection1.jpg"
                    alt="mobile van"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col>

                {/* <Col lg="6" className='gallery-img'>
                <div className="about-img mt-4">
                  <img
                    src="images/Intersection1.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col> */}
              
            </Row>
            </div>
            </section>

            {/* <section className="section pt-0">
          <div className="container pt-0">
          <Row className="align-items-center pt-0">
            <Col lg='12'>
                <h1 className="mb-4 green-heading">
                Images of Farm
                </h1>
                <div className="title-border-gallery"></div>

            </Col>
                
                  <Col lg="6" className='gallery-img'>
                <div className="about-img mt-4">
                  <img
                    src="images/Group_260.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col>
                <Col lg="6" className='gallery-img'>
                <div className="about-img mt-4">
                  <img
                    src="images/contact-farm.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                </Col>
                
              
            </Row>
            </div>
            </section> */}
            
            
            
          
       
        </div>
    )
}
