import React from 'react'
import { Row, Col} from 'reactstrap';

export default function Quote() {
    
    return (
       
            <section className=' quote-section'>
                <div className='container'>
                    <Row className='text-center'>
                        <Col lg='12' className='center-mob'>
                        <span><img style={{float:'left',width:'8%'}} alt='comma_left' src='./images/comma1.svg' /></span>
                            <h1 className='bg-fnt text-left text-white mt-20 mb-0'>
                                Be a part of
                            </h1>
                            <h1 className='bg-fnt why-quote text-right text-white mb-0'>
                                 our Journey
                            </h1>
                            <span className='ml-4' ><img style={{float:'right',width:'8%',marginRight:'2rem'}} alt='comma_right' src='./images/comma.svg' /></span>
                        </Col>
                    </Row>
                </div>
            </section>
       
    )
}
