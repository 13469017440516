import React from "react";
import { Row, Col, Button, CardText, CardTitle, Card } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Alert } from "reactstrap";
import axios from "axios";
const API_PATH = "//www.farmkrisp.com/contact.php";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);

    this.state = {
      status: false,
    };
  }

  onDismiss() {
    this.setState({
      status: false,
    });
  }

  handleValidSubmit(event, values) {
    const body = {
      name: values.Name,
      email: values.Email,
      phone: values.Mobile,
      city: values.City,
      message: values.Message,
      // "communication":values.Communication
    };

    console.log(body);

    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: body,
    })
      .then((result) => {
        console.log(result);

        this.setState({
          status: true,
        });

        this.form && this.form.reset();
      })
      .catch((error) => this.setState({ error: error.message }));

    const form = document.forms["contact-form"];

    fetch(
      `https://script.google.com/macros/s/AKfycbzf3xvtIWfALBJyt124ErEpsFyUiimH5SnO7dG7kcVDNBc_nFLT/exec`,
      { method: "POST", body: new FormData(form) }
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            status: true,
          });
          this.form && this.form.reset();
        }
      })
      .catch((error) => console.error("Error!", error.message));

    // axios({
    //   method: "post",
    //   url: ` `,

    //   data: body,
    // }).then((res) => {
    //     if (res.status === 200) {
    //         this.setState({
    //             status:true
    //         })
    //           } else {
    //               console.log('error');
    //   }

    // });
  }

  // handleValidSubmit(event, values) {
  //     const form = document.forms['contact-form']

  //     fetch(`https://script.google.com/macros/s/AKfycbxrTX26reEi4bOcQ9N9wSVjaV_gQGeLmzeOURNTXNyIQXTqqpsP/exec`, { method: 'POST', body: new FormData(form)})
  //             .then((res) =>{
  //                 if (res.status === 200) {
  //                     this.setState({
  //                         status:true
  //                     })
  //                     this.form && this.form.reset();
  //                       }
  //             })
  //             .catch(error => console.error('Error!', error.message))
  // }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <Col lg="12" className="text-center mb-4">
              <h1 className="mb-4 mt-mob green-heading">Get in Touch</h1>
              <div className="title-border-contact"></div>
            </Col>
            <Row className="ml-0-mob mr-0-mob">
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                name="contact-form"
                ref={(c) => (this.form = c)}
                method="post"
              >
                <Row className="pt-4 mt-4">
                  <Col lg="6">
                    <div className="form-group   app-label ">
                      <AvField
                        name="Name"
                        className="form-radius"
                        type="text"
                        validate={{ required: { value: true } }}
                        label="Full Name*"
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group   app-label ">
                      <AvField
                        name="Email"
                        className="form-radius"
                        type="text"
                        validate={{ required: { value: true, email: true } }}
                        label="Email*"
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="form-group   app-label ">
                      <AvField
                        name="Mobile"
                        className="form-radius"
                        type="text"
                        validate={{ required: { value: true } }}
                        label="Phone Number*"
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="form-group   app-label ">
                      <AvField
                        name="City"
                        className="form-radius"
                        type="text"
                        validate={{ required: { value: true } }}
                        label="City*"
                      />
                    </div>
                  </Col>

                  <Col lg="12">
                    <div className="form-group app-label ">
                      <AvField
                        name="Message"
                        className="form-radius"
                        type="textarea"
                        rows="4"
                        label="Describe Your Query"
                      />
                    </div>
                  </Col>

                  <Col lg="12" className="text-center">
                    <div className="form-group app-label">
                      <AvField
                        name="Communication"
                        type="checkbox"
                        label="I agree to receive other communications from FarmKrisp."
                      />
                    </div>
                  </Col>

                  <Col lg="12" className="text-center mb-mob">
                    <Button
                      type="submit"
                      name="submit"
                      className="contact-button"
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col lg="12" className="text-center mt-4">
                    {this.state.status ? (
                      <Alert toggle={this.onDismiss} color="success">
                        Our team will contact you soon!
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
              </AvForm>

              {/* <form method="post" action="./contact.php">
                        <label>
                            Name:
                            <div className="form-group app-label">
                            <input type="text" name="name" />
                            </div>
                            <div className="form-group app-label" >
                            <input type="text" name="lastname" />
                            </div>
                        </label>
                        <input type="submit" name= "submitted" value="Submit" />
                        </form> */}

              {/* <form>
                    <input type="text" id="fname" name="fname" value="John"> </input><br></br>
                    <label for="lname">Last name:</label><br></br>
                    <input type="text" id="lname" name="lname" value="Doe"> </input><br></br>
                    <input type="submit" value="Submit"> </input>
                    </form>           */}
            </Row>
          </div>
        </section>

        <section className="section mt-0 pt-0">
          <div className="container">
            <Row className="d-flex justify-content-center">
              <Col sm="12" className="contact-model text-center">
                <Card body>
                  <CardTitle className="big-heading-2 green-heading">
                    Corporate Address
                  </CardTitle>
                  <CardText className="f-20">
                    <h4 className="fnt-green">Ajeet Retail LLP</h4>
                    <a href="#">info@farmkrisp.com</a>
                    <br></br>
                    <a href="#">7527000006</a>
                    <br></br>
                    <p className="mb-0">
                      3rd floor, A-303, ELANTE OFFICES, INDUSTRIAL AREA, PHASE
                      1,
                    </p>
                    <p>Chandigarh, 160002</p>
                  </CardText>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Contact;
