import React from 'react'
import { Row, Col } from "reactstrap";


export default function FarmToFork() {
    return (
        <div>
            <section className="farmSection">
          <div className="container">
            <Row className="align-items-center">
                <Col lg='12' style={{marginTop:'6rem'}}>
                <div className='farm-font font-weight-bold'>
                    Farm To Fork
                </div>
                </Col>
                </Row>
                <Row className='justify-content-end'>

                <Col lg='3' >
                
                <p className='content farm-text text-white font-weight-light' style={{textAlign:'right'}}>
                    Superior quality fruits & veggies
                     from Tri City, grown with
                    sustainable farming methods
                    from a unique single origin,
                    minimally processed, packed
                    to preserve freshness &
                    directly sent to you
                </p>
                <div className="title-border-farm"></div>
                </Col>
                </Row>
            
          </div>
        </section>
        </div>
    )
}
