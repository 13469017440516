import React from 'react'
import { Row, Col} from "reactstrap";


export default function AboutSection() {
    return (
        <div>
            <section className="section bg-about">
          <div className="container">
            <Row className="align-items-center">
                <Col lg='8' className='p-4 mt-4'>
            <h1 className='text-white mt-4 pt-4'>
            The true strength of FarmKrisp lies in our
            farms and farmers. We aim to improve
            efficiencies in supply chain that leads to
            higher margins in the business.
            </h1>
            </Col>
            </Row>
          </div>
        </section>
        </div>
    )
}
